<template>
    <article>
        <PageBuilderInterpreter
            v-if="content_json"
            :components-json="content_json"
            tag="div"
        />

        <HtmlLinkHandler
            v-else
            #default="{ clickHandler }"
            @click="openCookieModal($event)"
        >
            <PageLayout
                :type="page_layout"
                @click.native="clickHandler($event)"
            >
                <template #left>
                    <SidebarMenu :is-mobile="isMobile">
                        <StaticHtmlLinkHandler>
                            <StaticBlock
                                v-if="showSidebar && sidebarCmsBlock.content"
                                :content="sidebarCmsBlock.content"
                            />
                        </StaticHtmlLinkHandler>
                    </SidebarMenu>
                </template>

                <template #content>
                    <BaseHeading tag="h1" theme="h3" class="heading">
                        {{ heading }}
                    </BaseHeading>

                    <CmsWithAccordions>
                        <div class="content" v-html="content" />
                    </CmsWithAccordions>
                </template>
            </PageLayout>
        </HtmlLinkHandler>
    </article>
</template>

<script>
import { createNamespacedHelpers, mapState } from 'vuex';

import { PSB_SIDEBAR_MENU } from '@configs/sidebar';
import { META_ROBOTS_INDEX_FOLLOW } from '@configs/seo';
import {
    MODAL_TYPE_SETTINGS,
    MODAL_TYPE_PROVIDERS,
} from '@configs/modal-consents';

import {
    LAYOUT_TYPE_2_COLUMNS_LEFT,
    LAYOUT_TYPE_2_COLUMNS_LEFT_BREADCRUMBS,
    LAYOUT_TYPE_2_COLUMNS_RIGHT,
} from '@types/CmsPage';
import { ERROR_ACTION_TAG_NAME } from '@types/Errors';

import CmsPageNotFoundException from '@exceptions/services/cms/CmsPageNotFoundException';

import { ASYNC_SEOMATIC_SERVICE_CONFIG } from '@async-services/seomatic/meta';

import { isRejected } from '@assets/promise';
import { getUrlForSeomatic, makeSeomaticRedirect } from '@assets/seomatic';

import PageBuilderInterpreter from '@modules/page-builder/components/PageBuilderInterpreter/PageBuilderInterpreter';

import BasePage from '@pages/mixins/BasePage';

import HtmlLinkHandler from '@functionals/HtmlLinkHandler/HtmlLinkHandler';
import CmsWithAccordions from '@functionals/CmsWithAccordions/CmsWithAccordions';

import BaseHeading from '@atoms/BaseHeading/BaseHeading';
import StaticBlock from '@atoms/StaticBlock/StaticBlock';

import PageLayout from '@molecules/PageLayout/PageLayout';
import StaticHtmlLinkHandler from '@molecules/StaticHtmlLinkHandler/StaticHtmlLinkHandler';

import SidebarMenu from '@organisms/SidebarMenu/SidebarMenu';

const { mapGetters: mapCmsBlockGetters } = createNamespacedHelpers('cmsBlock');
const { mapActions: mapConsentsActions } = createNamespacedHelpers('consents');

export default {
    name: 'Cms',

    components: {
        BaseHeading,
        CmsWithAccordions,
        PageLayout,
        SidebarMenu,
        StaticHtmlLinkHandler,
        StaticBlock,
        PageBuilderInterpreter,
        HtmlLinkHandler,
    },

    mixins: [BasePage()],

    layout: () => 'default',

    async asyncData({ app, params, error, store, req, route, redirect }) {
        const urlForSeomatic = getUrlForSeomatic(req, route);
        const { identifier } = params;
        let seomatic = null;

        const [cmsPagePromise, seomaticPromise] = await app.$fetchInParallel([
            app.$services.cms.getPageBuilderPage(identifier),
            app.$asyncServices.use(
                ASYNC_SEOMATIC_SERVICE_CONFIG.NAME,
                ASYNC_SEOMATIC_SERVICE_CONFIG.METHODS.GET_SEO,
                {
                    url: urlForSeomatic,
                }
            ),
            store.dispatch('cmsBlock/getCmsBlocks', {
                cmsBlocksToLoad: PSB_SIDEBAR_MENU,
            }),
        ]);

        seomatic = seomaticPromise.value;

        if (seomatic?.redirect) {
            try {
                return makeSeomaticRedirect(seomatic.redirect, redirect, route);
            } catch (err) {
                app.$errorHandler.captureError(
                    err,
                    {
                        [ERROR_ACTION_TAG_NAME]: 'makeSeomaticRedirect',
                    },
                    {
                        urlForSeomatic,
                        seomatic,
                    }
                );
            }
        }

        if (isRejected(cmsPagePromise)) {
            const err = cmsPagePromise.reason;

            if (err instanceof CmsPageNotFoundException) {
                return error({
                    statusCode: err.statusCode,
                    message: err.message,
                });
            }

            return error({
                statusCode: 500,
                message: 'Error getting cms page.',
            });
        }

        const {
            title,
            meta_title,
            meta_description,
            content_heading,
            content,
            content_json,
            page_layout,
        } = cmsPagePromise?.value || {};

        return {
            page_layout,
            heading: seomatic?.seo?.name || content_heading,
            content,
            content_json,
            seomatic,
            page: {
                meta: {
                    title: meta_title || title,
                    description: meta_description,
                },
            },
        };
    },

    head() {
        const { page, seomatic } = this;
        const {
            title: seoTitle,
            description: seoDescription,
            robots: seoRobots,
        } = seomatic?.seo?.meta || {};
        const { canonical_url: seoCanonicalUrl } = seomatic?.seo || {};

        const title = seoTitle || page.meta.title;
        const description = seoDescription || page.meta.description;

        return {
            titleTemplate: titleChunk => titleChunk,
            title,
            link: [
                {
                    hid: 'canonical',
                    rel: 'canonical',
                    href: seoCanonicalUrl || this.canonicalUrl,
                },
            ],

            meta: [
                {
                    hid: 'robots',
                    name: 'robots',
                    content: this.$getMetaTagRobotsContent(
                        seoRobots || META_ROBOTS_INDEX_FOLLOW
                    ),
                },
                {
                    hid: 'title',
                    name: 'title',
                    content: title,
                },
                {
                    hid: 'og:title',
                    property: 'og:title',
                    content: title,
                },
                {
                    hid: 'description',
                    name: 'description',
                    content: description,
                },
                {
                    hid: 'og:description',
                    property: 'og:description',
                    content: description,
                },
            ],
        };
    },

    computed: {
        ...mapState(['isMobile']),
        ...mapCmsBlockGetters(['staticBlockById']),

        sidebarCmsBlock() {
            return this.staticBlockById(PSB_SIDEBAR_MENU) || {};
        },

        showSidebar() {
            return [
                LAYOUT_TYPE_2_COLUMNS_LEFT,
                LAYOUT_TYPE_2_COLUMNS_RIGHT,
                LAYOUT_TYPE_2_COLUMNS_LEFT_BREADCRUMBS,
            ].includes(this.page_layout);
        },
    },

    methods: {
        ...mapConsentsActions([
            'setIsCookieSettingsModalOpen',
            'setIsTrustedPartnersModalOpen',
        ]),

        openCookieModal(event) {
            const modalType = event.target.getAttribute('data-modal-type');

            if (modalType === MODAL_TYPE_SETTINGS) {
                this.setIsCookieSettingsModalOpen(true);
            } else if (modalType === MODAL_TYPE_PROVIDERS) {
                this.setIsTrustedPartnersModalOpen(true);
            }
        },
    },
};
</script>

<style lang="scss" scoped>
.heading {
    @apply mt-4;
}

@import './assets/scss/cms/components/cms-page';

:deep() {
    .sidebar-menu {
        @apply mt-4;

        .static-pages-menu {
            .menu-button {
                @apply hidden;
            }

            .menu-content {
                .title,
                .close {
                    @apply hidden;
                }
                .subtitle {
                    @apply font-bold text-text text-m leading-m;
                }

                .links-list {
                    @apply py-3;

                    .menu-link {
                        @apply mb-2;

                        a {
                            @apply text-text;
                        }
                    }
                }
            }
        }
        .base-paragraph {
            @apply mb-3;
        }

        .text-link {
            @apply text-text;
        }

        .logout-btn {
            @apply text-primary;
        }
    }
}

@screen lg {
    .heading {
        @apply text-xl leading-xl;
    }
}
</style>
